<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(login)">
      <div class="col-md-4 ml-auto mr-auto">
        <card class="card-login card-plain">
          <div slot="header">
            <div class="logo-container">
              <img
                src="img/now-logo.png"
                alt="">
            </div>
          </div>

          <div>
            <ValidationProvider
              v-slot="{ passed, failed }"
              name="email"
              rules="required|email">
              <fg-input
                v-model="email"
                type="email"
                :error="failed ? 'The Email field is required' : null"
                :has-success="passed"
                class="no-border form-control-lg"
                placeholder="Email"
                addon-left-icon="now-ui-icons ui-1_email-85" />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ passed, failed }"
              name="password"
              rules="required|min:5">
              <fg-input
                v-model="password"
                type="password"
                :error="failed ? 'The Password field is required' : null"
                :has-success="passed"
                class="no-border form-control-lg"
                placeholder="Password"
                addon-left-icon="now-ui-icons ui-1_lock-circle-open" />
            </ValidationProvider>
          </div>

          <div slot="footer">
            <n-button
              native-type="submit"
              type="primary"
              round
              block>
              Get Started
            </n-button>
          </div>
        </card>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from 'vee-validate';
  import { required, email, min } from 'vee-validate/dist/rules';

  extend('email', email);
  extend('required', required);
  extend('min', min);

  export default {
    name: 'Login',
    data() {
      return {
        email: '',
        password: '',
      };
    },
    methods: {
      async login() {
        // eslint-disable-next-line no-shadow
        const email = this.email;
        const password = this.password;

        try {
          await this.$store.dispatch('login', { email, password });
          if (localStorage.getItem('token')) {
            await this.$store.dispatch('fetchUser', localStorage.getItem('token'));
          }
          await this.$router.push({ path: '/dashboard' });
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
