var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('div',{staticClass:"col-md-4 ml-auto mr-auto"},[_c('card',{staticClass:"card-login card-plain"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":"img/now-logo.png","alt":""}})])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"no-border form-control-lg",attrs:{"type":"email","error":failed ? 'The Email field is required' : null,"has-success":passed,"placeholder":"Email","addon-left-icon":"now-ui-icons ui-1_email-85"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"no-border form-control-lg",attrs:{"type":"password","error":failed ? 'The Password field is required' : null,"has-success":passed,"placeholder":"Password","addon-left-icon":"now-ui-icons ui-1_lock-circle-open"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('n-button',{attrs:{"native-type":"submit","type":"primary","round":"","block":""}},[_vm._v(" Get Started ")])],1)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }